<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('research_manage.component_organization') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="component-organization" :class="'btn btn-success text-light'">{{ $t('research_manage.component_organization') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Organization Type" vid="org_type" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="org_type"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('research_manage.organization_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.org_type"
                                    :options="organizationType"
                                    id="org_type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    @change="orgChange()"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_type == 1">
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_type == 1">
                            <ValidationProvider name="Designation"  vid="designation_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="designation_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.designation')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.designation_id"
                                    :options="designationList"
                                    id="designation_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_type == 1">
                            <ValidationProvider name="Coordinator Name"  vid="user_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="user_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('research_manage.coordinator_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.user_id"
                                    :options="userList"
                                    @change="getUserInfo(formData.user_id)"
                                    id="user_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="formData.org_type == 2 || formData.org_type == 3">
                          <b-col lg="6" sm="12">
                            <ValidationProvider :name="formData.org_type == 2 ? $t('research_manage.component_organization_name_en', 'en') : $t('research_manage.university_name_en', 'en')" vid="component_organization_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="component_organization_name"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ formData.org_type == 2 ? $t('research_manage.component_organization_name_en') : $t('research_manage.university_name_en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="component_organization_name"
                                    v-model="formData.component_org_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.org_id ? true : false"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider :name="formData.org_type == 2 ? $t('research_manage.component_organization_name_bn', 'en') : $t('research_manage.university_name_bn', 'en')" vid="component_organization_name_bn" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="component_organization_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ formData.org_type == 2 ? $t('research_manage.component_organization_name_bn') : $t('research_manage.university_name_bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="component_organization_name_bn"
                                    v-model="formData.component_org_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.org_id ? true : false"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Component Address" vid="component_address">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="component_organization_address"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ formData.org_type == 2 ? $t('research_manage.component_organization_address_en') : $t('research_manage.univ_address_en') }}
                                </template>
                                <b-form-textarea
                                    id="component_organization_address"
                                    v-model="formData.component_org_address"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.org_id ? true : false"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Component Address (Bn)" vid="component_organization_address_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="component_organization_address_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ formData.org_type == 2 ? $t('research_manage.component_organization_address_bn') : $t('research_manage.univ_address_bn') }}
                                </template>
                                <b-form-textarea
                                    id="component_organization_address_bn"
                                    v-model="formData.component_org_address_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.org_id ? true : false"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider :name="formData.org_type == 2 ? $t('research_manage.component_organization_email', 'en') : $t('research_manage.univ_email', 'en')" vid="component_organization_email" rules="required|email">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="component_organization_email"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ formData.org_type == 2 ? $t('research_manage.component_organization_email') : $t('research_manage.univ_email') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="component_organization_email"
                                      v-model="formData.component_org_email"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <div class="col-md-12 dam-form">
                            <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{$t('research_manage.coordinator_information')}}</legend>
                                <b-col>
                                  <b-row>
                                    <b-col v-if="formData.org_type == 2 || formData.org_type == 3" lg="6" sm="12">
                                      <ValidationProvider name="Coordinator Name (En)" vid="coord_name" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="5"
                                              label-for="coord_name"
                                              slot-scope="{ valid, errors }"
                                          >
                                              <template v-slot:label>
                                                  {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input class="form-control"
                                                  :disabled="formData.org_type == 1"
                                                  v-model="formData.coord_name"
                                                  id="coord_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col v-if="formData.org_type == 2  || formData.org_type == 3" lg="6" sm="12">
                                        <ValidationProvider name="Coordinator Name (Bn)" vid="coord_name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_name_bn"
                                                id="coord_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col v-if="formData.org_type == 2 || formData.org_type == 3" lg="6" sm="12">
                                        <ValidationProvider name="Designation (En)" vid="coord_designation" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_designation"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.designation_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_designation"
                                                id="coord_designation"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col v-if="formData.org_type == 2 || formData.org_type == 3" lg="6" sm="12">
                                        <ValidationProvider name="Designation (Bn)" vid="coord_designation_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_designation_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.designation_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_designation_bn"
                                                id="coord_designation_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Mobile No" vid="coord_mobile_no" rules="required|numeric|max:11|min:11">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_mobile_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_mobile_no"
                                                id="coord_mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col v-if="formData.org_type == 2 || formData.org_type == 3" lg="6" sm="12">
                                        <ValidationProvider name="Phone No" vid="coord_phone_no" rules="numeric|max:11">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_phone_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.phone_no')}}
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_phone_no"
                                                id="coord_phone_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Email" vid="coord_email" rules="email">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="coord_email"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.email')}}
                                            </template>
                                            <b-form-input class="form-control"
                                                :disabled="formData.org_type == 1"
                                                v-model="formData.coord_email"
                                                id="coord_email"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                  </b-row>
                                </b-col>
                            </fieldset>
                          </div>
                        </b-row>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <router-link to="component-organization" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                          </div>
                        </div>
                      </b-overlay>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
  .dam-form .is-valid, .is-invalid {
    background-image: none !important;
    padding-right: 0px !important;
  }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { componentOrganizationStore, componentOrganizationUpdate, usersByDesignationApi } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_type: 2,
        component_org_name: '',
        component_org_name_bn: '',
        component_org_address: '',
        component_org_address_bn: '',
        component_org_email: '',
        org_id: 0,
        designation_id: 0,
        user_id: 0,
        coord_name: '',
        coord_name_bn: '',
        coord_designation: '',
        coord_designation_bn: '',
        coord_mobile_no: '',
        coord_phone_no: '',
        coord_email: ''
      },
      designationList: [],
      userList: [],
      userListByDesignation: []
    }
  },
  created () {
    if (this.$route.query.id) {
        const tmp = this.getFormData(this.$route.query.id)
        this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  computed: {
    orgList: function () {
      return this.$store.state.orgList.filter(item => item.status === 0)
    },
    organizationType: function () {
      return this.$store.state.AgriResearch.commonObj.organizationType.map(item => {
        return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.getDesignationList()
    },
    'formData.designation_id': function (newValue) {
        this.getUserList()
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.formData.org_id) {
                this.getDesignationList()
            }
            this.loadUserList()
        }
    }
  },
  methods: {
    getFormData (Id) {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(Id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    getUserInfo (userId) {
      const Obj = this.userListByDesignation.find(item => item.user_id === userId)
      this.formData.coord_name = Obj.name
      this.formData.coord_name_bn = Obj.name_bn
      this.formData.coord_mobile_no = Obj.phone_no
      this.formData.coord_email = Obj.email
    },
    async getUserList () {
      if (this.formData.org_id) {
        const Mydata = {
          designation_id: this.formData.designation_id
        }
        this.loading = true
        const result = await RestApi.getData(authServiceBaseUrl, usersByDesignationApi, Mydata)
        if (!result.success) {
          this.loading = false
        } else {
          const data = result.data
          this.userListByDesignation = data
          if (this.userListByDesignation.length > 0) {
            this.loadUserList()
          }
        }
        this.loading = false
      }
    },
    loadUserList () {
      this.userList = this.userListByDesignation.map(Obj => {
        if (this.$i18n.locale === 'en') {
            return { value: Obj.user_id, text: Obj.name }
        } else {
            return { value: Obj.user_id, text: Obj.name_bn }
        }
      })
    },
    getDesignationList () {
        this.designationList = this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === this.formData.org_id).map(Obj => {
          this.formData.coord_designation = Obj.text_en
          this.formData.coord_designation_bn = Obj.text_bn
          if (this.$i18n.locale === 'en') {
              return { value: Obj.value, text: Obj.text_en }
          } else {
              return { value: Obj.value, text: Obj.text_bn }
          }
        })
    },
    orgChange () {
      if (this.formData.org_type === 2) {
        this.formData.org_id = 0
        this.formData.component_org_name = ''
        this.formData.component_org_name_bn = ''
        this.formData.component_org_address = ''
        this.formData.component_org_address_bn = ''
        this.formData.component_org_email = ''
        this.formData.designation_id = 0
        this.formData.user_id = 0
        this.formData.coord_name = ''
        this.formData.coord_name_bn = ''
        this.formData.coord_designation = ''
        this.formData.coord_designation_bn = ''
        this.formData.coord_mobile_no = ''
        this.formData.coord_phone_no = ''
        this.formData.coord_email = ''
        this.designationList = []
        this.userList = []
        this.userListByDesignation = []
      }
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${componentOrganizationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, componentOrganizationStore, this.formData)
      }
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/agri-research-service/research-management/configuration/component-organization')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
